import React from "react";
const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function getFormattedDate(
  date,
  prefomattedDate = false,
  hideYear = false
) {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} at ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day} ${month} at ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day} ${month} ${year} at ${hours}:${minutes}`;
}

export function timeAgo(dateParam, short = false) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();
  // const isThisMonth = today.getMonth() === MONTH_NAMES[date.getMonth()];

  if (seconds < 5) {
    return "now";
  } else if (seconds < 60) {
    if (short) {
      return `${seconds} seconds`;
    } else {
      return `${seconds} seconds ago`;
    }
  } else if (seconds < 90) {
    if (!short) {
      return "about a minute ago";
    }
  } else if (minutes < 60) {
    if (short) {
      return `${minutes} minutes`;
    } else {
      return `${minutes} minutes ago`;
    }
  } else if (isToday) {
    return getFormattedDate(date, "Today"); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, "Yesterday"); // Yesterday at 10:20
    // } else if (isThisMonth) {
    //   return
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10. January at 10:20
  }

  return getFormattedDate(date); // 10. January 2017. at 10:20
}

export function dayTimeAgo(dateParam, short = false) {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const today = new Date();
  const DAY_IN_MS = 86400000;
  const yesterday = new Date(today - DAY_IN_MS);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  if (isToday) {
    return "Today";
  } else if (isYesterday) {
    return "Yesterday";
  } else if (isThisYear) {
    return short ? formatDate("dd MMM", date) : formatDate("dd MMMM", date);
  }
  return short ? formatDate("dd MMM", date) : formatDate("dd MMMM", date);
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function formatDate(format, string = null, utc) {
  if (string) {
    var date = new Date(string.toString().replace(/-/g, "/"));
  } else {
    var date = new Date();
  }
  var MMMM = [
    "\x00",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var MMM = [
    "\x01",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dddd = [
    "\x02",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  function ii(i, len) {
    var s = i + "";
    len = len || 2;
    while (s.length < len) s = "0" + s;
    return s;
  }

  var y = utc ? date.getUTCFullYear() : date.getFullYear();
  format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
  format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
  format = format.replace(/(^|[^\\])y/g, "$1" + y);

  var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
  format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
  format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
  format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
  format = format.replace(/(^|[^\\])M/g, "$1" + M);

  var d = utc ? date.getUTCDate() : date.getDate();
  format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
  format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
  format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
  format = format.replace(/(^|[^\\])d/g, "$1" + d);

  var H = utc ? date.getUTCHours() : date.getHours();
  format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
  format = format.replace(/(^|[^\\])H/g, "$1" + H);

  var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
  format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
  format = format.replace(/(^|[^\\])h/g, "$1" + h);

  var m = utc ? date.getUTCMinutes() : date.getMinutes();
  format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
  format = format.replace(/(^|[^\\])m/g, "$1" + m);

  var s = utc ? date.getUTCSeconds() : date.getSeconds();
  format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
  format = format.replace(/(^|[^\\])s/g, "$1" + s);

  var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
  format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])f/g, "$1" + f);

  var A = H < 12 ? "AM" : "PM";
  format = format.replace(/(^|[^\\])AA+/g, "$1" + A);
  format = format.replace(/(^|[^\\])A/g, "$1" + A.charAt(0));

  var a = A.toLowerCase();
  format = format.replace(/(^|[^\\])aa+/g, "$1" + a);
  format = format.replace(/(^|[^\\])a/g, "$1" + a.charAt(0));

  var tz = -date.getTimezoneOffset();
  var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
  if (!utc) {
    tz = Math.abs(tz);
    var tzHrs = Math.floor(tz / 60);
    var tzMin = tz % 60;
    K += ii(tzHrs) + ":" + ii(tzMin);
  }
  format = format.replace(/(^|[^\\])K/g, "$1" + K);

  var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
  format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
  format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

  format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
  format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

  format = format.replace(/\\(.)/g, "$1");

  return format;
}

export function formatISODate(format, string = null, utc) {
  if (string) {
    var date = new Date(string);
  } else {
    var date = new Date();
  }

  var MMMM = [
    "\x00",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var MMM = [
    "\x01",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var dddd = [
    "\x02",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  function ii(i, len) {
    var s = i + "";
    len = len || 2;
    while (s.length < len) s = "0" + s;
    return s;
  }

  var y = utc ? date.getUTCFullYear() : date.getFullYear();
  format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
  format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
  format = format.replace(/(^|[^\\])y/g, "$1" + y);

  var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
  format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
  format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
  format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
  format = format.replace(/(^|[^\\])M/g, "$1" + M);

  var d = utc ? date.getUTCDate() : date.getDate();
  format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
  format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
  format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
  format = format.replace(/(^|[^\\])d/g, "$1" + d);

  var H = utc ? date.getUTCHours() : date.getHours();
  format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
  format = format.replace(/(^|[^\\])H/g, "$1" + H);

  var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
  format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
  format = format.replace(/(^|[^\\])h/g, "$1" + h);

  var m = utc ? date.getUTCMinutes() : date.getMinutes();
  format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
  format = format.replace(/(^|[^\\])m/g, "$1" + m);

  var s = utc ? date.getUTCSeconds() : date.getSeconds();
  format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
  format = format.replace(/(^|[^\\])s/g, "$1" + s);

  var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
  format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
  f = Math.round(f / 10);
  format = format.replace(/(^|[^\\])f/g, "$1" + f);

  var A = H < 12 ? "AM" : "PM";
  format = format.replace(/(^|[^\\])AA+/g, "$1" + A);
  format = format.replace(/(^|[^\\])A/g, "$1" + A.charAt(0));

  var a = A.toLowerCase();
  format = format.replace(/(^|[^\\])aa+/g, "$1" + a);
  format = format.replace(/(^|[^\\])a/g, "$1" + a.charAt(0));

  var tz = -date.getTimezoneOffset();
  var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
  if (!utc) {
    tz = Math.abs(tz);
    var tzHrs = Math.floor(tz / 60);
    var tzMin = tz % 60;
    K += ii(tzHrs) + ":" + ii(tzMin);
  }
  format = format.replace(/(^|[^\\])K/g, "$1" + K);

  var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
  format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
  format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

  format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
  format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

  format = format.replace(/\\(.)/g, "$1");

  return format;
}

export function isSameDay(date1, date2) {
  if (date1 && date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.toISOString().slice(0, 10) === d2.toISOString().slice(0, 10);
  }
  return false;
}

export function parseLanguage(lng) {
  if (lng === "sp") return "Spanish";
  else if (lng === "gr") return "German";
  else if (lng === "rs") return "Russian";
  else if (lng === "it") return "Italian";
  else if (lng === "fr") return "French";
  else if (lng === "en") return "English";
  else return lng;
}

export function sentenceCase(strval) {
  var newstrs = strval.split(".");
  var finalstr = "";
  for (var i = 0; i < newstrs.length; i++)
    finalstr =
      finalstr +
      "." +
      newstrs[i].substr(0, 1).toUpperCase() +
      newstrs[i].substr(1);
  return finalstr.substr(1);
}

export function titleCase(str) {
  return str.replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export function validateEmail(Email) {
  var pattern =
    /^([\w-]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
  return Email.trim().match(pattern) ? true : false;
}

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export function getURLDomain(url) {
  if (url) {
    var result;
    var match;
    if (
      (match = url.match(
        /(^([^\/]+):\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/im
      ))
    ) {
      result = match[3];
    }
    return result;
  }
}

export function getFileType(file) {
  if (file) {
    var imageFileType = ["jpg", "jpeg", "gif", "png", "svg"];
    var audioFileType = ["mp3", "wav", "m4a", "mp4a"];
    var videoFileType = ["webm", "mp4", "mov", "mkv", "wmv"];
    var documentFileType = ["pdf", "doc", "docx", "xls", "xlsx", "pst", "txt"];
    var archiveFileType = ["zip", "tar.gz"];
    var fileExtension = file.split(".").pop();
    if (fileExtension) {
      if (imageFileType.includes(fileExtension)) {
        return "image";
      } else if (audioFileType.includes(fileExtension)) {
        return "audio";
      } else if (videoFileType.includes(fileExtension)) {
        return "video";
      } else if (documentFileType.includes(fileExtension)) {
        return "document";
      } else if (archiveFileType.includes(fileExtension)) {
        return "archive";
      } else {
        return false;
      }
    }
  }
}

// Absolute URL's ONLY AND INCLUDING PROTOCOL http:// || ://
export function getFilenameFromUrl(url) {
  if (url) {
    const pathname = new URL(url).pathname;
    const index = pathname.lastIndexOf("/");
    return -1 !== index ? pathname.substring(index + 1) : pathname;
  }
}

export function basename(string) {
  if (string) {
    return string.split("/").pop();
  }
}

export function getThumbnail(url) {
  let type = getFileType(url);
  switch (type) {
    case "image":
      return "ri-image-fill";
    case "video":
      return "ri-film-fill";
    case "audio":
      return "ri-music-2-fill";
    case "file":
      return "ri-file-text-fill";
    case "archive":
      return "ri-folder-zip-fill";
    case "document":
      return "ri-file-text-fill";
    default:
      return "ri-file-unknow-fill";
  }
}

export function isInViewport(element) {
  if (isElement(element) || isNode(element)) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
}

export function isNode(o) {
  return typeof Node === "object"
    ? o instanceof Node
    : o &&
        typeof o === "object" &&
        typeof o.nodeType === "number" &&
        typeof o.nodeName === "string";
}

export function isElement(o) {
  return typeof HTMLElement === "object"
    ? o instanceof HTMLElement //DOM2
    : o &&
        typeof o === "object" &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === "string";
}

export function getFileExtension(file) {
  if (file) {
    return file.split(".").pop();
  }
}

export function useTraceUpdate(props) {
  const prev = React.useRef(props);
  React.useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log("Changed props:", changedProps);
    }
    prev.current = props;
  });
}

//API for time and date functions
export var timeAndDateHandling = {
  /** Computes the elapsed time since the moment the function is called in the format mm:ss or hh:mm:ss
   * @param {String} startTime - start time to compute the elapsed time since
   * @returns {String} elapsed time in mm:ss format or hh:mm:ss format if elapsed hours are 0.
   */
  getElapsedTime: function (startTime) {
    // Record end time
    let endTime = new Date();

    // Compute time difference in milliseconds
    let timeDiff = endTime.getTime() - startTime.getTime();

    // Convert time difference from milliseconds to seconds
    timeDiff = timeDiff / 1000;

    // Extract integer seconds that dont form a minute using %
    let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)

    // Pad seconds with a zero if neccessary
    let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";

    // Convert time difference from seconds to minutes using %
    timeDiff = Math.floor(timeDiff / 60);

    // Extract integer minutes that don't form an hour using %
    let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds

    // Pad minutes with a zero if neccessary
    let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";

    // Convert time difference from minutes to hours
    timeDiff = Math.floor(timeDiff / 60);

    // Extract integer hours that don't form a day using %
    let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds

    // Convert time difference from hours to days
    timeDiff = Math.floor(timeDiff / 24);

    // The rest of timeDiff is number of days
    let days = timeDiff;

    let totalHours = hours + days * 24; // add days to hours
    let totalHoursAsString =
      totalHours < 10 ? "0" + totalHours : totalHours + "";

    if (totalHoursAsString === "00") {
      return minutesAsString + ":" + secondsAsString;
    } else {
      return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
    }
  },
};

export function getWindowWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export function getWindowHeight() {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function switchURL(page, title, url) {
  if ("undefined" !== typeof window.history.pushState) {
    window.history.pushState({ page: page }, title, url);
    document.title = title;
  } else {
    window.location.assign(url);
    document.title = title;
  }
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}

export function isJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function flatenResponse(data, parent = "") {
  if (data) {
    var newData = {};
    if (!parent || parent == "") {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] == "object") {
          newData = { ...newData, ...flatenResponse(data[key], `${key}`) };
        } else {
          newData[key] = data[key];
        }
      });
    } else {
      Object.keys(data).forEach((key) => {
        if (typeof data[key] == "object") {
          newData = {
            ...newData,
            ...flatenResponse(data[key], `${parent}.${key}`),
          };
        } else {
          newData[`${parent}.${key}`] = data[key];
        }
      });
    }
    return newData;
  }
}

export function recursiveLoop(obj, callback, path = []) {
  if (typeof obj === "string") {
    try {
      obj = JSON.parse(obj);
    } catch (e) {}
  }

  let hasChildren = false;
  let elements = [];

  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      hasChildren = true;
      const children = recursiveLoop(item, callback.bind(null, index), [
        ...path,
        index,
      ]);
      elements = elements.concat(children);
    });
  } else if (typeof obj === "object" && obj !== null) {
    Object.entries(obj).forEach(([key, value]) => {
      hasChildren = true;
      const children = recursiveLoop(value, callback.bind(null, key), [
        ...path,
        key,
      ]);
      elements = elements.concat(children);
    });
  }

  // Leaf node
  const element = callback(null, obj, hasChildren, path);
  if (element) {
    elements.push(element);
  }

  return elements;
}

export function findPath(data, value) {
  const path = []; // to store the path
  let found = false; // to break the loop when value is found

  const traverse = (obj, parentKey) => {
    if (found) return; // break if value is already found

    if (Array.isArray(obj)) {
      obj.forEach((item, index) => {
        traverse(item, index);
      });
    } else if (typeof obj === "object" && obj !== null) {
      Object.entries(obj).forEach(([key, val]) => {
        if (val === value) {
          path.push(parentKey ? `${parentKey}.${key}` : key);
          found = true;
          return;
        }
        traverse(val, parentKey ? `${parentKey}.${key}` : key);
      });
    }
  };

  traverse(data, "");

  return found ? path : null; // return null if value is not found
}

export function fixJSONArray(s) {
  // preserve newlines, etc - use valid JSON
  s = s
    .replace(/\\n/g, "\\n")
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, "\\&")
    .replace(/\\r/g, "\\r")
    .replace(/\\t/g, "\\t")
    .replace(/\\b/g, "\\b")
    .replace(/\\f/g, "\\f");
  // remove non-printable and other non-valid JSON chars
  s = s.replace(/[\u0000-\u0019]+/g, "");
  return s;
}

export function toArray(data) {
  if (Array.isArray(data)) {
    // If data is already an array, return it as-is
    return data;
  } else if (data === null || data === undefined) {
    // If data is null or undefined, return an empty array
    return [];
  } else {
    // Otherwise, wrap the data in a new array and return it
    return [data];
  }
}

export function removeDoubleBraces(str) {
  return str.replace(/\{\{|\}\}/g, "");
}

export function exportObjectToChrFile(objectToExport, fileName) {
  try {
    const json = JSON.stringify(objectToExport);
    const blob = new Blob([json], { type: "application/json" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.atchr`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    console.log(e);
  }
}

export function extractVariablesFromUtterance(utterance) {
  const variableRegex = /(\[|\{\{|%)(.+?)(\]|\}\}|%)/g;
  const matches = utterance.match(variableRegex);
  if (matches) {
    // Keep the curly braces in the matched variables
    // return matches.map((match) => match);
    // Remove the characters [] {{ }} %
    return matches.map((match) => match.replace(/[\[\]{}%]/g, ""));
  }
  return [];
}

export async function importChrFileToObject(file) {
  try {
    const json = await file.text();
    const object = JSON.parse(json);
    return object;
  } catch (error) {
    return null;
  }
}

export const hasActiveSubscription = (subscriptions) => {
  if (
    !subscriptions ||
    !Array.isArray(subscriptions) ||
    subscriptions.length === 0
  ) {
    return false;
  }

  return subscriptions.some((subscription) => {
    if (subscription && subscription.expiry) {
      const expiryDate = new Date(subscription.expiry);
      const currentDate = new Date();

      return (
        expiryDate > currentDate &&
        (subscription.status === 1 || subscription.status === undefined)
      );
    }

    return false;
  });
};

export const getActiveSubscription = (subscriptions) => {
  if (
    !subscriptions ||
    !Array.isArray(subscriptions) ||
    subscriptions.length === 0
  ) {
    return null;
  }

  return subscriptions.find((subscription) => {
    if (subscription && subscription.expiry) {
      const expiryDate = new Date(subscription.expiry);
      const currentDate = new Date();

      return (
        expiryDate > currentDate &&
        (subscription.status === 1 || subscription.status === undefined)
      );
    }

    return false;
  });
};
