import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

//i18n
import i18n from '../i18n';

// flags
import ar from "../assets/images/flags/ar.jpg";
import en from "../assets/images/flags/en.jpg";
import fr from "../assets/images/flags/fr.jpg";
import gr from "../assets/images/flags/gr.jpg";
import it from "../assets/images/flags/it.jpg";
import jp from "../assets/images/flags/jp.jpg";
import kr from "../assets/images/flags/kr.jpg";
import pt from "../assets/images/flags/pt.jpg";
import rs from "../assets/images/flags/rs.jpg";
import sp from "../assets/images/flags/sp.jpg";
import zh from "../assets/images/flags/zh.jpg";

import { setUserLanguage } from "../redux/actions";

function LanguageSelector(props) {

    const [lng, setlng] = useState("English");

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleLangSelector = () => setDropdownOpen(!dropdownOpen);

    /* changes language according to clicked language menu item */
    const changeLanguageAction = (e, lng) => {
        e.preventDefault();
        /* set the selected language to i18n */
        i18n.changeLanguage(lng);
        props.setUserLanguage(lng);
        if (lng === "ar")
            setlng("Arabic");
        else if (lng === "en")
            setlng("English");
        else if (lng === "fr")
            setlng("French");
        else if (lng === "gr")
            setlng("German");
        else if (lng === "it")
            setlng("Italian");
        else if (lng === "jp")
            setlng("Japanese");
        else if (lng === "kr")
            setlng("Korean");
        else if (lng === "pt")
            setlng("Portuguese");
        else if (lng === "rs")
            setlng("Russian");
        else if (lng === "sp")
            setlng("Spanish");
        else if (lng === "zh")
            setlng("Chinese");
    }

    return (
        <React.Fragment>
           <div className={dropdownOpen ? "language-switcher dropdown dropdown--open" : "language-switcher dropdown"} onClick={()=> toggleLangSelector()}>
             <div className="dropdown-handle language-switcher__handle">
               <i className="ri-global-line font-size-20"></i>
               <i className="mdi mdi-chevron-up dropdown-arrow"></i>
             </div>
             <div className="dropdown-content language-switcher__content">
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'ar')} className={lng === "Arabic" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={ar} className="language-switcher__flag" alt="Arabic" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'en')} className={lng === "English" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={en} className="language-switcher__flag" alt="English" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'fr')} className={lng === "French" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={fr} className="language-switcher__flag" alt="French" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'gr')} className={lng === "German" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={gr} className="language-switcher__flag" alt="German" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'it')} className={lng === "Italian" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={it} className="language-switcher__flag" alt="Italian" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'jp')} className={lng === "Japanese" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={jp} className="language-switcher__flag" alt="Japanese" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'kr')} className={lng === "Korean" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={kr} className="language-switcher__flag" alt="Korean" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'pt')} className={lng === "Portuguese" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={pt} className="language-switcher__flag" alt="Portuguese" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'rs')} className={lng === "Russian" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={rs} className="language-switcher__flag" alt="Russian" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'sp')} className={lng === "Spanish" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={sp} className="language-switcher__flag" alt="Spanish" />
                </Link>
                <Link to="/" onClick={(e) => changeLanguageAction(e, 'zh')} className={lng === "Chinese" ? "language-switcher__content-item active" : "language-switcher__content-item"} >
                  <img src={zh} className="language-switcher__flag" alt="Chinese" />
                </Link>
             </div>
           </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
  const { language } = state.Setting;
  return { language };
};

export default connect(mapStateToProps, {
  setUserLanguage,
})(LanguageSelector);