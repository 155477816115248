import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { APIClient } from "../../helpers/apiClient";

import * as Auth from "../../helpers/authUtils";

import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_USER,
  FORGET_PASSWORD,
  SET_USER_ID,
  VERIFY_USER,
  // API_FAILED
} from "./constants";

import {
  loginUserSuccess,
  registerUserSuccess,
  forgetPasswordSuccess,
  apiError,
  // verifyUser,
  // setUserID
} from "./actions";

/**
 * Sets the session
 * @param {*} user
 */

const create = new APIClient().create;

/**
 * Set USERID on verification page
 */
function* setUser({ payload: { user, history } }) {}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* verify({ payload: { user, history } }) {
  try {
    // const auth = await Auth.validateCode(user);
    try {
      if (Auth.verifyToken()) {
        const payload = Auth.getLoggedInUser();
        const response = yield call(
          create,
          process.env.REACT_APP_CHROMESQ_API + "/authy/",
          {
            action: "verify",
            app: process.env.REACT_APP_NAME,
            email: payload.email,
            code: user,
          }
        );
        if (response.result) {
          Auth.addTeamMember();
          localStorage.setItem("uid", payload.id);
          localStorage.removeItem("doVerification");
          yield put(loginUserSuccess(payload));
        } else {
          yield put(apiError("Invalid verification code."));
        }
      } else {
        history.push("/login");
      }
    } catch (error) {
      yield put(apiError(error));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password, history } }) {
  try {
    const response = yield call(
      create,
      process.env.REACT_APP_API + "/user/login",
      {
        email: username,
        password,
      }
    );
    if (response && response.token) {
      localStorage.setItem("token", response.token);
      yield put(loginUserSuccess(response));
      const verification = Auth.doVerification();
      if (verification) {
        yield put(apiError(""));
        history.push("/verify");
      } else {
        yield put(apiError("An error has occured, Please try again"));
      }
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Register the user
 */
function* register({ payload: { user, history } }) {
  try {
    const email = user.email;
    const password = user.password;
    const response = yield call(
      create,
      process.env.REACT_APP_API + "/user/register",
      {
        name: user.fullname,
        email,
        password,
        affiliate: user?.affiliate,
        status: 0,
      }
    );

    if (response && response.token) {
      const token = response.token;
      const id = response.id;
      Auth.setLoggedInUser({ token });
      yield put(registerUserSuccess(response));
      history.push("/confirm/" + token);
      // const verification = Auth.doVerification();
      // if (verification) {
      //   yield put(apiError(""));
      //   history.push("/verify/" + id);
      // } else {
      //   yield put(apiError("An error has occured, Please try again"));
      // }
    } else {
      yield put(apiError(response.message));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
  try {
    Auth.logoutAuthUser();
    yield call(() => {
      history.push("/login");
    });
  } catch (error) {}
}

/**
 * forget password
 */
function* forgetPassword({ payload: { email } }) {
  try {
    const response = yield call(create, process.env.REACT_APP_API + "/user/reset", { email });
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

export function* watchSetUser() {
  yield takeEvery(SET_USER_ID, setUser);
}

export function* watchVerifyUser() {
  yield takeEvery(VERIFY_USER, verify);
}

// export function* watchApiError() {
//     yield takeEvery(API_FAILED, apiError);
// }

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgetPassword),
    fork(watchSetUser),
    fork(watchVerifyUser),
    // fork(watchApiError),
  ]);
}

export default authSaga;
