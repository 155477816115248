import {
  USER_ENTITIES,
  REMOVE_ENTITY,
  UPDATE_ENTITY_MEMBERS,
  INIT_ENTITIES,
  UPDATE_ENTITY,
} from "./constants";

const INITIAL_STATE = {
  entities: [],
};

const Resources = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_ENTITIES:
      return {
        ...state,
        entities: state.entities.filter((entity) =>
          action.payload.includes(entity._id)
        ),
      };

    case REMOVE_ENTITY:
      return {
        ...state,
        entities: state.entities.filter(
          (entity) => entity._id !== action.payload
        ),
      };

    case UPDATE_ENTITY_MEMBERS:
      return {
        ...state,
        entities: state.entities.map((entity) =>
          entity._id === action.payload.entityId
            ? { ...entity, team: action.payload.members }
            : entity
        ),
      };

    case INIT_ENTITIES:
      return {
        ...state,
        entities: action.payload,
      };

    case UPDATE_ENTITY:
      return {
        ...state,
        entities: state.entities.map((entity) =>
          entity._id === action.payload.entityId
            ? { ...entity, ...action.payload.property }
            : entity
        ),
      };

    default:
      return state;
  }
};

export default Resources;
