import { combineReducers } from 'redux';

import Auth from './auth/reducers';
import Chat from './chat/reducers';
import Layout from './layout/reducer';
import Misc from './misc/reducer';
import Resources from "./resources/reducers";
import Setting from './setting/reducer';

export default combineReducers({
  Auth,
  Chat,
  Layout,
  Misc,
  Resources,
  Setting,
});