import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next  } from "react-i18next";

import translationAR from './locales/ar/translation.json';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationGR from './locales/gr/translation.json';
import translationIT from './locales/it/translation.json';
import translationJP from './locales/jp/translation.json';
import translationKR from "./locales/kr/translation.json";
import translationPT from './locales/pt/translation.json';
import translationRS from './locales/rs/translation.json';
import translationSP from './locales/sp/translation.json';
import translationZH from './locales/zh/translation.json';

//translations
const resources = {
  ar: {
    translation: translationAR,
  },
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  gr: {
    translation: translationGR,
  },
  it: {
    translation: translationIT,
  },
  jp: {
    translation: translationJP,
  },
  kr: {
    translation: translationKR,
  },
  pt: {
    translation: translationPT,
  },
  rs: {
    translation: translationRS,
  },
  sp: {
    translation: translationSP,
  },
  zh: {
    translation: translationZH,
  },
};

i18n
    .use(detector)
    .use(initReactI18next ) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.i18nextLng || "en",
        fallbackLng: "en", // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
          escapeValue: false // react already safes from xss
        }
    });

export default i18n;
